import { Component, OnInit, ChangeDetectionStrategy, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { FoodNode, FoodFlatNode } from 'app/modules/admin/pages/skills/skills.types';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { SkillsService } from 'app/modules/admin/pages/skills/skills.service';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { Output, EventEmitter, Input } from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Application, BOOLEAN, common, errorMessages } from 'app/constants';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
})
export class SkillsComponent implements OnInit {
  totalRes;
  totalActiveNode;
  filterValue = '';
  selected_node = [];
  dataChange = new BehaviorSubject<FoodNode[]>(null);
  ERROR_MESSAGE = errorMessages;
  @Output() newItemEvent = new EventEmitter<any>();
  zero = Application.ZERO;
  oldskill: '';
  selectedData: {
    code: string;
    description: string;
    title: string;
    level: number;
    expandable: boolean;
  };

  @Input() set data(value) {

    this.oldskill = value; // this comes undefined
  }

  treeData = [];

  _unsubscribeAll: Subject<any> = new Subject<any>();

  isDrawerOpen = new BehaviorSubject<boolean>(BOOLEAN.FALSE);

  selectedNode;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FoodFlatNode, FoodNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<FoodNode, FoodFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: FoodFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<FoodFlatNode>;

  treeFlattener: MatTreeFlattener<FoodNode, FoodFlatNode>;

  dataSource: MatTreeFlatDataSource<FoodNode, FoodFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<FoodFlatNode>(BOOLEAN.TRUE /* multiple */);

  /// Filtering
  myControl = new FormControl();

  options: string[] = ['One', 'Two', 'Three'];

  filteredOptions: Observable<string[]>;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(readonly _skillsService: SkillsService, readonly _ngZone: NgZone, private readonly cdk: ChangeDetectorRef) {

    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );

    this.treeControl = new FlatTreeControl<FoodFlatNode>(
      this.getLevel,
      this.isExpandable
    );

    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    this._skillsService.skills$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((mainres) => {
        this.totalActiveNode = '';
        this.totalActiveNode = this.findNode(this.dataSource.data, this.selectedNode);
        const activeNode = this.findNode(this.dataSource.data, this.selectedNode);
        if (activeNode) {
          activeNode['children'] = mainres;
          this.treeData = this.dataSource.data;
          this.dataSource.data = this.treeData;
          this.treeControl.expand(activeNode);
        } else {
          mainres = mainres.map(item => {
            item.children = [];
            return item;
          });
          this.totalRes = mainres;
          this.treeData = mainres;
          this.dataSource.data = this.treeData;
        }
      });

    this.dataChange.subscribe(data => {
      if (data) {
        this.dataSource.data = data;
      }
    });
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if (
        (change as SelectionChange<FoodFlatNode>).added ||
        (change as SelectionChange<FoodFlatNode>).removed
      ) {
        if (change.added.length === Application.ONE) {
          this.handleTreeControl(change as SelectionChange<FoodFlatNode>);
        }
      }
    });
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl = (change: SelectionChange<FoodFlatNode>) => {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }


  toggleNode = (node: FoodFlatNode, expand: boolean) => {
    if (expand && node) {
      this.selectedNode = node;
      this.getChildNodes(node);
    }
  }



  ngOnInit(): void {
    const arr = this.oldskill.split(',');
    this.dataSource.data.map(element => {
      if (arr.includes(element.title)) {
        if (element){
          for (let i = Application.ZERO; i < this.treeControl.dataNodes.length; i++) {
            if (this.treeControl.dataNodes[i].code === element.code) {   
              this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
            }
          }
          this.selected_node.push({ ['code']: element.code, ['Title']: element.title });
        }
      }
    });
  }

  ngOnChanges(changes: any): void {
    if (changes.data.firstChange) {
      const arr = this.oldskill.split(',');
      this.dataSource.data.map(async (element) => {
        if (arr.includes(element.title)) {
          let activeNode = this.findNewNode(this.dataSource.data, element);
          const childrens = await this._skillsService.getChildSkills(element.code).toPromise();
          activeNode['children'] = childrens;
          childrens.map(item => {
            if (arr.includes(item.title)) {
              this.selected_node.push({ ['code']: item.code, ['Title']: item.title });
            }
          })
          this.cdk.markForCheck();
        }
      });
    }
  }


  triggerResize(): void {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(Application.ONE)).subscribe(() => this.autosize.resizeToFitContent(BOOLEAN.TRUE));
  }

  findNode = (data: any[], node: FoodFlatNode) => {
    if (!data || data.length === Application.ZERO || !node) {
      return undefined;
    }
    let actualNode;
    data.forEach(n => {
      if (n.code === node.code) {
        actualNode = n;
      }
      if (!actualNode && (n as any).children) {
        actualNode = this.findNode((n as any).children, node);
      }
      if (actualNode) {
        return;
      }
    });
    return actualNode;
  }

  findNewNode = (data: any[], node) => {
    if (!data || data.length === Application.ZERO || !node) {
      return undefined;
    }
    let actualNode;
    data.forEach(n => {
      if (n.code === node.code) {
        actualNode = n;
      }
      if (!actualNode && (n as any).children) {
        actualNode = this.findNewNode((n as any).children, node);
      }
      if (actualNode) {
        return;
      }
    });
    return actualNode;
  }

  getLevel = (node: FoodFlatNode) => node.level;

  isExpandable = (node: FoodFlatNode) => node.expandable;

  getChildren = (node: FoodNode): FoodNode[] => node.children;

  hasChild = (_: number, _nodeData: FoodFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: FoodFlatNode) => _nodeData.title === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: FoodNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.title === node.title
        ? existingNode
        : new FoodFlatNode();
    flatNode.title = node.title;
    flatNode.code = node.code;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FoodFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    if (descendants.length === Application.ZERO) {
      return BOOLEAN.FALSE;
    }
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  checkIfNodeSelected(node: FoodFlatNode): boolean {
    return this.selected_node.find(o => o.code === node.code);
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FoodFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  selectNode = (node) => {
    this.selectedNode = node;
  }
  getChildNodes = (node) => {
    this._skillsService.getSkills(node.code).subscribe((res) => { });
  }
  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FoodFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
   
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    descendants.every(child => {
      this.checklistSelection.isSelected(child);
    });
    this.getSelectedItems();
    this.cdk.detectChanges();
  }
  
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FoodFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.getSelectedItems();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FoodFlatNode): void {
    let parent: FoodFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FoodFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FoodFlatNode): FoodFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < Application.ONE) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - Application.ONE;

    for (let i = startIndex; i >= Application.ZERO; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  getSelectedItems(): string {
    this.selected_node = [];
    if (!this.checklistSelection.selected.length && this.oldskill === '') {
      return 'C.S.I Skills';
    }
    if (this.checklistSelection.selected && this.checklistSelection.selected.length > 0) {
      this.newItemEvent.emit({ title: this.checklistSelection.selected.map(s => s.title).join(','), code: this.checklistSelection.selected.map(s => s.code).join(',') });
    } else {
      this.newItemEvent.emit({ title: '', code: '' });
    }
    this.checklistSelection.selected.map(s => {
      if (!this.selected_node.find(o => o.code === s.code)) {
     
        this.selected_node.push({ ['code']: s.code, ['Title']: s.title });
      }
    });
    return this.checklistSelection.selected.map(s => s.code + ' ' + '(' + s.title + ')').join(',');
  }

  removeCurrentNode(selectedNode): void {
    for (let i = Application.ZERO; i < this.treeControl.dataNodes.length; i++) {
      if (this.treeControl.dataNodes[i].code === selectedNode.code) {    
        this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
      }
    }
    const index = this.selected_node.indexOf(selectedNode);
    if (index >= Application.ZERO) {
      this.selected_node.splice(index, Application.ONE);
    }
  }

  removeNode = (nodedd: FoodFlatNode) => {

    this.checklistSelection.toggle(nodedd);
    this.checkAllParentsSelection(nodedd);
    this.treeControl.collapseAll();
    this.cdk.detectChanges();
    this.cdk.markForCheck();
    this.getSelectedItems();
  }

  filterChanged(filterText: string): void {
    // ChecklistDatabase.filter method which actually filters the tree and gives back a tree structure
    this.filter(filterText);
  }

  filter = (filterText: string) => {
    this.filterValue = filterText;
    if (filterText.length === Application.THREE && this.totalActiveNode) {
      this.dataSource.data = [];
    }
    if (filterText.length > Application.TWO) {
      this.searchSkills(filterText);
    }
    else if (filterText.length === Application.ZERO) {
      this._skillsService.getSkills().subscribe(res => { });
    }
  }

  searchSkills = (searchKey) => {
    this._skillsService.getSearchSkills(searchKey).subscribe((SearchResult) => {
    });
  }

  onkeydown = (e: Event) => {
    if (e[common.code] === common.enter) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }
}
