// -----------------------------------------------------------------------------------------------------
// @ AUTH UTILITIES
//
// Methods are derivations of the Auth0 Angular-JWT helper service methods
// https://github.com/auth0/angular2-jwt
// -----------------------------------------------------------------------------------------------------

import { Application } from "app/constants";

export class AuthUtils {
    /**
     * Constructor
     */
    constructor() {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Base64 decoder
     * Credits: https://github.com/atk
     *
     * @param str
     * @private
     */
    private static _b64decode(str: string): string {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let output = '';

        str = String(str).replace(/=+$/, '');

        if (str.length % Application.FOUR === Application.ONE) {
            throw new Error(
                '\'atob\' failed: The string to be decoded is not correctly encoded.'
            );
        }

        /* tslint:disable */
        for (
            // initialize result and counters
            let bc = Application.ZERO, bs: any, buffer: any, idx = Application.ZERO;
            // get next character
            (buffer = str.charAt(idx++));
            // character found in table? initialize bit storage and add its ascii value;
            ~buffer &&
                (
                    (bs = bc % Application.FOUR ? bs * Application.SIXTYFOUR + buffer : buffer),
                    // and if not first of each Application.FOUR characters,
                    // convert the first 8 bits to one ascii character
                    bc++ % Application.FOUR
                )
                ? (output += String.fromCharCode(Application.TWOHUNDREDFIFTYFIVE & (bs >> ((-Application.TWO * bc) & Application.SIX))))
                : Application.ZERO
        ) {
            // try to find character in table (Application.ZERO-63, not found => -Application.ONE)
            buffer = chars.indexOf(buffer);
        }
        /* tslint:enable */

        return output;
    }

    /**
     * Base64 unicode decoder
     *
     * @param str
     * @private
     */
    private static _b64DecodeUnicode(str: any): string {
        return decodeURIComponent(
            Array.prototype.map
                .call(this._b64decode(str), (c: any) => {
                    return '%' + ('00' + c.charCodeAt(Application.ZERO).toString(Application.SIXTEEN)).slice(-Application.TWO);
                })
                .join('')
        );
    }

    /**
     * URL Base 64 decoder
     *
     * @param str
     * @private
     */
    private static _urlBase64Decode(str: string): string {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % Application.FOUR) {
            case Application.ZERO:
                {
                    break;
                }
            case 2:
                {
                    output += '==';
                    break;
                }
            case Application.THREE:
                {
                    output += '=';
                    break;
                }
            default:
                {
                    throw Error('Illegal base64url string!');
                }
        }
        return this._b64DecodeUnicode(output);
    }

    /**
     * Decode token
     *
     * @param token
     * @private
     */
    private static _decodeToken(token: string): any {
        // Return if there is no token
        if (!token) {
            return null;
        }

        // Split the token
        const parts = token.split('.');

        if (parts.length !== Application.THREE) {
            throw new Error('The inspected token doesn\'t appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more.');
        }

        // Decode the token using the Base64 decoder
        const decoded = this._urlBase64Decode(parts[Application.ONE]);

        if (!decoded) {
            throw new Error('Cannot decode the token.');
        }

        return JSON.parse(decoded);
    }

    /**
     * Get token expiration date
     *
     * @param token
     * @private
     */
    private static _getTokenExpirationDate(token: string): Date | null {
        // Get the decoded token
        const decodedToken = this._decodeToken(token);

        // Return if the decodedToken doesn't have an 'exp' field
        if (!decodedToken.hasOwnProperty('exp')) {
            return null;
        }

        // Convert the expiration date
        const date = new Date(Application.ZERO);
        date.setUTCSeconds(decodedToken.exp);

        return date;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Is token expired?
     *
     * @param token
     * @param offsetSeconds
     */
    static isTokenExpired(token: string, offsetSeconds?: number): boolean {
        // Return if there is no token
        if (!token || token === '') {
            return true;
        }

        // Get the expiration date
        const date = this._getTokenExpirationDate(token);

        offsetSeconds = offsetSeconds || Application.ZERO;

        if (date === null) {
            return true;
        }

        // Check if the token is expired
        return !(date.valueOf() > new Date().valueOf() + offsetSeconds * Application.ONETHUOSAND);
    }
}
