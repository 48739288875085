export const common = {
  PAGE_size: 'page_size',
  blank: '',
  AND: '&',
  ATTHERATE: '@',
  QUESTIONMARK: '?',
  blankArray: [],
  VIDEO: 'video',
  IMAGE: 'image',
  MEDIA: 'media',
  EQUALTO: `===`,
  CONFIRM: 'confirmed',
  success: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  MESSAGE: 'message',
  Message: 'Message',
  COVERPHOTO: 'cover_photo',
  PROFILEHOTO: 'profile_pic',
  REMOVE: 'Remove',
  CONFIRM_LABEL: 'Confirm',
  type: 'type',
  POST: 'post',
  DELETE_POST: 'Delete Post',
  DEFAULTCOVERPHOTO: 'assets/images/pages/profile/cover.jpeg',
  DEFAULTAVTAR: 'assets/images/pages/default/profile-default.png',
  PASSWORDSUCCESFULLY: 'Password updated successfully',
  internetStatus: 'internetSTatus',
  Athorize: 'Authorization',
  registerWhenStable: 'registerWhenStable:30000',
  TOASTPOSITION: 'toast-top-right',
  NEWSWORKER: 'ngsw-worker.js',
  FIRSTDATE_FORMATE: 'DD-MM-YYYY',
  SECOND_DATE_FORMATE: 'MMM DD, YYYY',
  MONTH_LABEL: 'MMMM YYYY',
  DATE_LABEL: 'LL',
  MONTH_LABELYEAR: 'MMMM YYYY',
  EMAIL_VARIFY: 'email-verify',
  ACTICE: 'Active',
  INACTIVE: 'Inactive',
  SPACE: 'Space',
  allowedTypes: ['image/jpg', 'image/bmp', 'image/png', 'image/jpeg'],
  RESETPASS: 'reset-password',
  setPASS: 'set-password',
  goBack: 'GoBack',
  PROFILE: 'profile',
  INVENTORYTAG: 'fromInventory',
  propExtensions: ['jpg', 'JPG', 'jpeg', 'JPEG', 'bmp', 'BMP', 'png', 'PNG'],
  extensions: ['pdf', 'docx', 'doc', 'xls', 'jpeg', 'JPEG', 'bmp', 'png', 'PNG', 'jpg', 'JPG', 'odt'],
  fromCover: 'fromCover',
  enter: 'Enter',
  code: 'code',
  amber: 'amber',
  themeColor: 'curentTheme',
  news:'News'
};
export const Application = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  TWELVE: 12,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEENN: 15,
  SIXTEEN: 16,
  FIFTEEN: 50,
  SIXTYFOUR: 64,
  TWOHUNDREDFIFTYFIVE: 255,
  FOURHUNDRED: 400,
  FOURHUNDREDONE: 401,
  FOURHUNDREDTHREE: 403,
  FOURHUNDREDTEWNTEYTWO: 422,
  FIVEHUNDRED: 500,
  EIGHTHUNDRED: 900,
  ONETHUOSAND: 1000,
  ONETHOUSANDSFIFTEENHUNDRED: 1500,
  ONWTHOUSANDSEIGHTHUNDRED: 1800,
  TWOTHOUSAND: 2000,
  THREETHOUSAND: 3000,
  EIGHTTHOUSANDS: 8000,
  eightdigit: 20971520,
  NEGATIVEONE: -1,
  ZEROPOINTSEVENFIVE: 0.75,
  THIRTYONE: 31,
  FORTYEIGHT: 48,
  FIFTYSEVEN: 57
};

export const BOOLEAN = {
  TRUE: true,
  FALSE: false,
};

export const DropdownArray = [
  {'model': 'Maxima', 'id': Application.THIRTEEN},
  {'model': 'Skyline', 'id': Application.FOURTEEN},
  {'model': 'Taurus', 'id': Application.FIFTEENN},
  {'model': 'Escort', 'id': Application.SIXTEEN},
];
