
export const errorMessages = {
   DATA_REQUIRED: 'Data is required',
   USERNAME_REQUIRED: 'Username is required',
   USERNAMR_INVALID: 'Username can only use letters, numbers, underscore and periods',
   ENTERVALID_EMAIL: 'Please enter a valid email address',
   EMAILREQUIRED: 'Email address is required',
   PASSWORD_IS_REQUIRED: 'Password is required',
   PASSWORD_MAX_CHAR: 'Password should contain maxium 12 characters',
   PASSWORD_NOT_MATCH: 'Passwords must match',
   NO_DATA: 'No Data Available',
   PLEASE_ADD_IMAGE: 'please add image file ',
   PLEASE_ADD_ONLY_IMAGE : 'please add only image/jpeg or image/png',
   PLEASE_ADD_COVER_PHOTO: 'please add image cover photo ',
   PLEASE_WRITE_COMMENT: 'Please write comment.',
   INTERNET_NOT_CONNECT: 'Please check your internet connection and try again.',
   ACCEPETED_DATA_FORMAT: 'Only pdf, docx, doc, xls, jpeg, bmp, png, jpg, odt file extensions are allowed',
   CONFIRM_PASSWORD_REQUIRED: 'Password confirmation is required',
   ACCPETED_IMAGE_FORMAT: 'Only jpg, jpeg, bmp, png file extensions are allowed',
   TERMOFSERVICES: 'Terms of Service and Privacy Policy is required',
   NORESULTFOUND: 'No results found',
   SELECTAFILE: 'Please select a file'
};


export const commonMessages = {
REMOVE_PHOTO: 'Are you sure you want to remove this profile picture? This action cannot be undone!',
REMOVE_AVTAR: 'Remove Profile Picture',
REMOVE_COVER_PICTURE: 'Remove Cover Picture',
DELETEPOST: 'Are you sure you want to delete this post? This action cannot be undone!',
next_relies: 'Available in next release stay tuned!',
FILE_UPLOADED: 'File uploaded successfully.',
OK: 'ok',
PROFILE_UPDATED: 'Profile updated successfully',
COVERPIC_UPDARED: 'Cover photo updated successfully.',
REMOVE_COVER_MESSAGE: 'Are you sure you want to remove this cover picture? This action cannot be undone!',
PRODUCT_UPDATED: 'Product updated successfully',
REMOVE_PRODUCT_TITLE: 'Remove Product',
REMOVE_PRODUCT_MESSAGE: 'Are you sure you want to delete this product? This action cannot be undone!',
PRODUCT_DELETED: 'Product deleted successfully',
PRODUCT_ACTIVE: 'Product activated successfully',
PRODUCT_DEACTIVE: 'Product deactivated successfully',
PRODUCT_ACTIVE_TITLE: 'Activate product',
PRODUCT_DEACTIVE_TITLE: 'Deactivate product',
PRODUCT_ACTIVE_MESSAGE: 'Are you sure you want to activate this product?',
PRODUCT_DEACTIVE_MESSAGE: 'Are you sure you want to deactivate this product?',
THEMEUPDATED: 'Theme updated successfully',
PRIVATEPROFILEMSG : 'This profile is Private, you cant see this '
};


