import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'environments/environment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { Application , BOOLEAN , common} from './constants/index';
import {ImagecropperModule} from './modules/admin/pages/image-cropper/image-cropper.module';
const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy       : PreloadAllModules,
    relativeLinkResolution   : 'legacy'
};

const MY_DATE_FORMATS = {
    parse: {
      dateInput: common.FIRSTDATE_FORMATE,
    },

    display: {
      dateInput: common.SECOND_DATE_FORMATE,
      monthYearLabel: common.MONTH_LABEL,
      dateA11yLabel: common.DATE_LABEL,
      monthYearA11yLabel: common.MONTH_LABELYEAR
    },
}; 

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        // Fuse & Fuse Mock API
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core
        CoreModule,
        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),
        ServiceWorkerModule.register(common.NEWSWORKER, {
           enabled: environment.production,
           registrationStrategy: common.registerWhenStable
        }),

        ToastrModule.forRoot({
            timeOut: Application.EIGHTTHOUSANDS,
            positionClass: common.TOASTPOSITION,
            preventDuplicates: BOOLEAN.TRUE,
          }),
          ImagecropperModule
    ],
    bootstrap   : [
        AppComponent,
    ],
    
})
export class AppModule{}
