import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { followers } from "app/modules/admin/pages/followers/followers.types";
import { UserService } from "app/core/user/user.service";

@Injectable({
  providedIn: "root",
})
export class FollowersService {
  private _followers: BehaviorSubject<followers | null> = new BehaviorSubject(
    null
  );

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for search
   *
   * @param value
   */
  set items(value: any) {
    // Store the value
    this._followers.next(value);
  }

  get items$(): Observable<any> {
    return this._followers.asObservable();
  }

  /**
   * Get users & company
   */
  getUserFollowers() {
    var httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      }),
    };

    return this._httpClient
      .get<any>(
        environment.backend_url + "backend/get-followers-listing",
        httpOptions
      )
      .pipe(
        switchMap((res) => {
          for (let i = 0; i < res.data.length; i++) {
            let obj = res.data[i];
            if ("profile_pic" in obj) {
              obj["profile_pic"] = environment.BackendUrl + obj["profile_pic"];
            } else {
              obj["profile_pic"] =
                "assets/images/pages/default/profile-default.png";
            }
            if ("cover_photo" in obj) {
              obj["cover_photo"] = environment.BackendUrl + obj["cover_photo"];
            } else {
              obj["cover_photo"] =
                "assets/images/pages/profile/cover.jpeg";
            }
          }
          this.items = res.data;
          return of(res.data);
        })
      );
  }

  // follow

  follow(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      }),
    };

    return this._httpClient
      .post(environment.backend_url + "backend/follow", data, httpOptions)
      .pipe(
        map((res: any) => {
          this.getUserFollowers().subscribe((res: any) => {
            this.items = res;
          });
          this._userService.getUser().subscribe((res: any) =>{
            this._userService.user = res;
          });
        })
      );
  }

  //unfollow
  unfollow(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      }),
    };

    return this._httpClient
      .post(environment.backend_url + "backend/unfollow", data, httpOptions)
      .pipe(
        map((res: any) => {
          this.getUserFollowers().subscribe((res: any) => {
            this.items = res;
          });
          this._userService.getUser().subscribe((res: any) =>{
            this._userService.user = res;
          });
        })
      );
  }

  //remove followers
  removeUserFollowers(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      }),
    };

    return this._httpClient
      .post(
        environment.backend_url + "backend/remove-follower",
        data,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          this.getUserFollowers().subscribe((res: any) => {
            this.items = res;
          });
          this._userService.getUser().subscribe((res: any) =>{
            this._userService.user = res;
          });
        })
      );
  }
}
