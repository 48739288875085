import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DotsService {

  constructor(private _httpClient: HttpClient) {
  }

  private _dots: BehaviorSubject<any | null> = new BehaviorSubject(null);


  /**
   * Setter & getter for getSkills(search)
   *
   * @param value
   */
  set dots(value: any) {
    // Store the value
    this._dots.next(value);
  }

  get dots$(): Observable<any> {
    return this._dots.asObservable();
  }

  /**
   * get skills API's
   */

  getDots = (parent_id: any = '') => {
    parent_id = parent_id || '';
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-dots/' + parent_id,
        httpOptions
      )
      .pipe(
        switchMap((res) => {
          this.dots = res.data;
          return of(res.data);
        })
      );
  }

  getDotsBySearch = (searchKey) => {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-dots/' + '?searchKeyword=' + searchKey,

        httpOptions
      )
      .pipe(
        switchMap((res) => {
          this.dots = res.data;
          return of(res.data);
        })
      );
  }

  getChildDots(parent_id: any = '') {
    parent_id = parent_id || '';
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-dots/' + parent_id,
        httpOptions
      ).pipe(
        switchMap((res) => {
          return of(res.data);
        })
      );
  }
}
