/**
 * Node for to-do item
 */
export class FoodNode {
  children?: FoodNode[];
  id: string;
  description: string;
  value: string;
  parent_id: string;
}

/** Flat to-do item node with expandable and level information */
export class FoodFlatNode {
  id: string;
  description: string;
  value: string;
  level: number;
  expandable: boolean;
  parent_id: string;
  code?: string;
}
