import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Skills } from 'app/modules/admin/pages/profile/portfolio/portfolio.types';


@Injectable({
  providedIn: 'root'
})
export class SkillsService {

  constructor(private _httpClient: HttpClient) {
  }
  private _skills: BehaviorSubject<Skills | null> = new BehaviorSubject(null);

  /**
   * Setter & getter for getSkills(search)
   *
   * @param value
   */
  set skills(value: any) {
    // Store the value
    this._skills.next(value);
  }

  get skills$(): Observable<any> {
    return this._skills.asObservable();
  }

  /**
   * get skills API's
   */

  getSkills(parent_id: any = '') {
    parent_id = parent_id || '';
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-skills/' + parent_id,
        httpOptions
      ).pipe(
        switchMap((res) => {
          this.skills = res.data
          return of(res.data);
        })
      );
  }
  getSearchSkills = (searchKeyWord) => {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-skills' + '?searchKeyword=' + searchKeyWord,
        httpOptions
      ).pipe(
        switchMap((res) => {
          this.skills = res.data;
          return of(res.data);
        })
      );
  }
  getChildSkills(parent_id: any = '') {
    parent_id = parent_id || '';
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-skills/' + parent_id,
        httpOptions
      ).pipe(
        switchMap((res) => {
          return of(res.data);
        })
      );
  }
}
