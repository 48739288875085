<div *ngIf="selected_node.length != zero">
    <mat-chip-list aria-label="Fish selection">
        <mat-chip *ngFor="let selectedItem of selected_node">{{ selectedItem.value }} <button
                matChipRemove (click)="removeCurrentNode(selectedItem)">
                <mat-icon>cancel</mat-icon>
            </button></mat-chip>
    </mat-chip-list>
</div>
<mat-form-field class=" fuse-mat-no-subscript w-full">
    <input matInput
        placeholder="U.S. D.O.T"  (input)="filterChanged($event.target.value)" (keydown)="onkeydown($event)"
        [matAutocomplete]="auto"/>
    <mat-icon class="mat-icon-rtl-mirror arrow-icon-size" matSuffix>arrow_drop_down</mat-icon>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option disabled *ngIf="dataSource.data.length > zero">Please select an item from below</mat-option>
        <mat-option disabled *ngIf="dataSource.data.length == zero" class="hideOption"></mat-option>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node) && checkIfNodeSelected(node)"
                    (change)="todoLeafItemSelectionToggle(node)">{{node.value}}</mat-checkbox>
            </mat-tree-node>


            <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <mat-checkbox [checked]="checkIfNodeSelected(node)"
                    [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)"> {{node.value}}</mat-checkbox>
            </mat-tree-node>
        </mat-tree>
        <div *ngIf="dataSource.data.length == zero">
            <h1 class="text-center text-lg font-medium text-secondary"> {{ERROR_MESSAGE.NORESULTFOUND}}</h1>
        </div>
    </mat-autocomplete>
</mat-form-field>