import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Router } from '@angular/router';
import { HomeService } from 'app/modules/landing/home/home.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { customOptions } from '../../../../../@fuse/services/config/config.constants';
import { Location } from '@angular/common';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { Application } from 'app/constants';
@Component({
    selector: 'post-details',
    templateUrl: './details.component.html',
    encapsulation: ViewEncapsulation.None
})
export class HomePostDetails implements OnInit, OnDestroy {
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private routeSub: any;
    postId: any;
    post: any;
    varient_backend = environment.varient_backend_url;
    strippedImage: any;
    customOptions = customOptions;
    /**
     * Constructor
     */
    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private router: Router,
        private _homeService: HomeService,
        private _location: Location,
        readonly splashService: FuseSplashScreenService
    ) {

        this.postId = /[^/]*$/.exec(router.url)[0];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        /*// Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Set the drawerMode and drawerOpened if
                if ( matchingAliases.includes('lg') )
                {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                }
                else
                {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }
            });*/

        this.getPostDetails(this.postId);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getPostDetails(postId) {
        this.splashService.show();
        this._homeService.getPostDetails(postId).pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                // Store the data
                this.post = data.success[0].data.post;
                setTimeout(() => {
                    this.splashService.hide();
                }, Application.ONETHUOSAND);
            });
    }

    backClicked() {
        this._location.back();
      }

}
