import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from '../../../environments/environment';
import { assign, cloneDeep } from 'lodash-es';
import { Application } from 'app/constants';

@Injectable()
export class AuthService
{

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
    };

    public _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('access_token') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post(environment.backend_url + 'auth/forget-password', email, this.httpOptions);
    }
    
    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(data): Observable<any>
    {
        return this._httpClient.post(environment.backend_url + 'auth/reset-password', data, this.httpOptions);
    }
 
    /**
     * Set password
     *
     * @param password
     */    
    setPassword(data: any): Observable<any> {
        return this._httpClient.post(environment.backend_url + 'auth/set-user-password', data, this.httpOptions)
    }

    
    resendVerifiedLink(data: any): Observable <any> {
        return this._httpClient.post(environment.backend_url + 'auth/resend-verified-link', data, this.httpOptions);
    }

    


    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials): Observable<any>{
        // Throw error, if the user is already logged in
        if ( this._authenticated ){
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(environment.backend_url + 'auth/login', credentials, this.httpOptions).pipe(
            switchMap((res: any) => {

                // Store the access token in the local storage
                this.accessToken = res.data;
                this.getUser(this.accessToken).subscribe(

                    (res: any) => {
                        var userData = res.data[Application.ZERO];
                        userData['status'] = 'online';
                        userData['avatar'] = 'assets/images/avatars/brian-hughes.jpg';
                        userData['cover'] = 'assets/images/avatars/brian-hughes.jpg'
                        this._userService.user = userData;
                    },

                    (errorResponse) => {
                        
                    }
                );
                // Set the authenticated flag to true
                this._authenticated = true;


                // Return a new observable with the response
                return of(res);
            })
        )
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            access_token: this.accessToken
        }).pipe(
            catchError(() => {

                // Return false
                return of(false);
            }),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                 // this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('access_token');
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(data): Observable<any>
    {
        return this._httpClient.post(environment.backend_url + 'auth/register', data, this.httpOptions);
    }

    
      email_verify(user: any): Observable<any>
      {
        return this._httpClient.post(environment.backend_url + 'auth/email-verfied', user, this.httpOptions);
      }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string, password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        return of(true);

    }

    getUser = (token) => {
        var httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            })
        };
        return this._httpClient.get(environment.backend_url + 'backend/get-current-user', httpOptions);
           
    }
}
