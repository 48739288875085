
/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        // subtitle: 'Unique dashboard designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'dashboards.posts',
                title: 'Posts',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/dashboards/posts'
            },
            {
                id: 'dashboards.project',
                title: 'Projects',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/dashboards/project'
            },
            {
                id: 'dashboards.analytics',
                title: 'Analytics',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/analytics'
            }
        ]
    },
    {
        id: 'apps',
        title: 'Applications',
        // subtitle: 'Custom made application designs',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'apps.contacts',
                title: 'Employees',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/apps/employees'
            },
            {
                id: 'apps.ecommerce.inventory',
                title: 'Inventory',
                type: 'basic',
                link: '/apps/ecommerce/inventory',
                icon: 'heroicons_outline:shopping-cart',
            },
            {
                id: 'apps.file-manager',
                title: 'File manager',
                type: 'basic',
                icon: 'heroicons_outline:cloud',
                link: '/apps/file-manager'
            },
            {
                id: 'apps.chat',
                title: 'Communications',
                type: 'basic',
                icon: 'heroicons_outline:chat-alt',
                link: '/apps/chat'
            },
            // {
            //     id: 'apps.chat',
            //     title: 'Products',
            //     type: 'basic',
            //     icon: 'heroicons_outline:shopping-cart',
            //     link: '/apps/product'
            // },
            {
                id: 'apps.chat',
                title: 'Projects',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/pages/profile/projects'
            },
            {
                id: 'apps.chat',
                title: 'Portfolio',
                type: 'basic',
                icon: 'heroicons_outline:document-text',
                link: '/pages/profile/portfolio'
            },
            {
                id: 'apps.chat',
                title: 'Jobs',
                type: 'basic',
                icon: 'heroicons_outline:briefcase',
                link: '/apps/jobs'
            },
            {
                id: 'apps.chat',
                title: 'Certificate',
                type: 'basic',
                icon: 'heroicons_outline:document-text',
                link: '/apps/certificate'
            },
            {
                id: 'apps.chat',
                title: 'Qualification',
                type: 'basic',
                icon: 'heroicons_outline:academic-cap',
                link: '/apps/qualification'
            },
            {
                id: 'apps.chat',
                title: 'Licence',
                type: 'basic',
                icon: 'heroicons_outline:identification',
                link: '/apps/licence'
            },
            {
                id: 'apps.chat',
                title: 'Material',
                type: 'basic',
                icon: 'heroicons_outline:truck',
                link: '/apps/material'
            },
            {
                id: 'apps.chat',
                title: 'Services',
                type: 'basic',
                icon: 'heroicons_outline:support',
                link: '/apps/services'
            },
            // {
            //     id: 'apps.ecommerce',
            //     title: 'ECommerce',
            //     type: 'collapsable',
            //     icon: 'heroicons_outline:shopping-cart',
            //     children: [
            //         {
            //             id: 'apps.ecommerce.inventory',
            //             title: 'Inventory',
            //             type: 'basic',
            //             link: '/apps/ecommerce/inventory'
            //         }
            //     ]
            // },


            // {
            //     id      : 'apps.help-center',
            //     title   : 'Help center',
            //     type    : 'collapsable',
            //     icon    : 'heroicons_outline:support',
            //     link    : '/apps/help-center',
            //     children: [
            //         {
            //             id        : 'apps.help-center.home',
            //             title     : 'Home',
            //             type      : 'basic',
            //             link      : '/apps/help-center',
            //             exactMatch: true
            //         },
            //         {
            //             id   : 'apps.help-center.faqs',
            //             title: 'FAQs',
            //             type : 'basic',
            //             link : '/apps/help-center/faqs'
            //         },
            //         {
            //             id   : 'apps.help-center.guides',
            //             title: 'Guides',
            //             type : 'basic',
            //             link : '/apps/help-center/guides'
            //         },
            //         {
            //             id   : 'apps.help-center.support',
            //             title: 'Support',
            //             type : 'basic',
            //             link : '/apps/help-center/support'
            //         }
            //     ]
            // },
            // {
            //     id   : 'apps.mailbox',
            //     title: 'Mailbox',
            //     type : 'basic',
            //     icon : 'heroicons_outline:mail',
            //     link : '/apps/mailbox',
            //     badge: {
            //         title  : '27',
            //         classes: 'px-2 bg-pink-600 text-white rounded-full'
            //     }
            // },
            // {
            //     id   : 'apps.tasks',
            //     title: 'Tasks',
            //     type : 'basic',
            //     icon : 'heroicons_outline:check-circle',
            //     link : '/apps/tasks'
            // }

        ]
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'aside',
        icon: 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'posts',
        title: 'Posts',
        type: 'aside',
        icon: 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'apps',
        title: 'Apps',
        type: 'aside',
        icon: 'heroicons_outline:qrcode',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'aside',
        icon: 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'user-interface',
        title: 'UI',
        type: 'aside',
        icon: 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'navigation-features',
        title: 'Navigation',
        type: 'aside',
        icon: 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];

export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'apps',
        title: 'APPS',
        type: 'group',
        children: [
            {
                id: 'apps.dashboards.posts',
                title: 'Posts',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/analytics'
            },
            {
                id: 'apps.dashboards.analytics',
                title: 'Analytics',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/analytics'
            },
            {
                id: 'apps.dashboards.project',
                title: 'Project',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-check',
                link: '/dashboards/project'
            },
            {
                id: 'apps.calendar',
                title: 'Calendar',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: '/apps/calendar'
            },
            {
                id: 'apps.contacts',
                title: 'Contacts',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/apps/contacts'
            },
            {
                id: 'apps.ecommerce',
                title: 'ECommerce',
                type: 'collapsable',
                icon: 'heroicons_outline:shopping-cart',
                children: [
                    {
                        id: 'apps.ecommerce.inventory',
                        title: 'Inventory',
                        type: 'basic',
                        link: '/apps/ecommerce/inventory'
                    }
                ]
            },
            {
                id: 'apps.file-manager',
                title: 'File manager',
                type: 'basic',
                icon: 'heroicons_outline:shopping-cart',
                link: '/apps/file-manager'
            },
            {
                id: 'apps.help-center',
                title: 'Help center',
                type: 'collapsable',
                icon: 'heroicons_outline:support',
                link: '/apps/help-center',
                children: [
                    {
                        id: 'apps.help-center.home',
                        title: 'Home',
                        type: 'basic',
                        link: '/apps/help-center',
                        exactMatch: true
                    },
                    {
                        id: 'apps.help-center.faqs',
                        title: 'FAQs',
                        type: 'basic',
                        link: '/apps/help-center/faqs'
                    },
                    {
                        id: 'apps.help-center.guides',
                        title: 'Guides',
                        type: 'basic',
                        link: '/apps/help-center/guides'
                    },
                    {
                        id: 'apps.help-center.support',
                        title: 'Support',
                        type: 'basic',
                        link: '/apps/help-center/support'
                    }
                ]
            },
            {
                id: 'apps.mailbox',
                title: 'Mailbox',
                type: 'basic',
                icon: 'heroicons_outline:mail',
                link: '/apps/mailbox',
                badge: {
                    title: '27',
                    classes: 'px-2 bg-black bg-opacity-25 text-white rounded-full'
                }
            },
            {
                id: 'apps.tasks',
                title: 'Tasks',
                type: 'basic',
                icon: 'heroicons_outline:check-circle',
                link: '/apps/tasks'
            }
        ]
    },
    {
        id: 'others',
        title: 'OTHERS',
        type: 'group'
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'aside',
        icon: 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'user-interface',
        title: 'User Interface',
        type: 'aside',
        icon: 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'navigation-features',
        title: 'Navigation Features',
        type: 'aside',
        icon: 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'posts',
        title: 'Posts',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'apps',
        title: 'Apps',
        type: 'group',
        icon: 'heroicons_outline:qrcode',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'pages',
        title: 'Pages',
        type: 'group',
        icon: 'heroicons_outline:document-duplicate',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'user-interface',
        title: 'UI',
        type: 'group',
        icon: 'heroicons_outline:collection',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id: 'navigation-features',
        title: 'Misc',
        type: 'group',
        icon: 'heroicons_outline:menu',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];

export const verticalNavigation: FuseNavigationItem[] = [
    {
        id: 'posts',
        title: 'Posts',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
