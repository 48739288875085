import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from 'app/app.types';
import { environment } from '../../src/environments/environment';
import { UserService } from '../app/core/user/user.service'
import { FollowersService } from 'app/modules/admin/pages/followers/followers.service';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient,
                private _userService: UserService,
                private _followersService: FollowersService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData>{   
        var httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            }),
          };

        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._httpClient.get<any>('api/common/messages'),
            this._httpClient.get<any>(environment.backend_url + 'backend/get-navigation', httpOptions),
            this._httpClient.get<any>('api/common/notifications'),
            this._httpClient.get<any>('api/common/shortcuts'),
            this._httpClient.get<any>('api/common/user'),
            this._userService.getUser(),
            this._followersService.getUserFollowers(),
            this._userService.getProjectsListing(),
            this._userService.getPortfolioListing(),
            this._userService.getSkills(),
            
        ]).pipe(
            map(([messages, navigation, notifications, shortcuts, user, followers, projects, portfolio, skills]) => ({
                    messages,
                    navigation,
                    notifications,
                    shortcuts,
                    user,
                    followers,
                    projects,
                    portfolio,
                    skills
                })
            )
        );
    }
}
