import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { IsCompanyGuard } from 'app/core/apps/employee/guards/is_company.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { OptionalGuard } from 'app/core/auth/guards/optional.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { PostDetail } from 'app/modules/admin/dashboards/posts/detail/detail.component';
import { HomePostDetails } from 'app/modules/landing/home/post-details/details.component';
// import { HomePostDetail } from 'app/modules/landing/home/post-detail/detail.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboards/project'
  { path: '', pathMatch: 'full', redirectTo: '/home' },

  // Redirect signed in user to the '/dashboards/project'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: '/pages/profile',
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    // resolve: {
    //   initialData: InitialDataResolver,
    // },
    children: [
      {
        path: 'home',
        // loadChildren: () =>
        //   import('app/modules/landing/home/home.module').then(
        //     (m) => m.HomeModule
        //   ),
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then(
            (m) => m.HomeModule
          ),
      },
      {
        path: 'post/details/:id',
        component: HomePostDetails,
      },
    ]
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    // resolve: {
    //   initialData: InitialDataResolver,
    // },
    children: [
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'resend-link',
        loadChildren: () =>
          import(
            'app/modules/auth/resend-verified-link/resend-verified-link.module'
          ).then((m) => m.ResendVerifiedLinkModule),
      },
      {
        path: 'reset-password/:user_id',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('app/modules/auth/privacy-policy/privacy-policy.module').then(
            (m) => m.PrivacyPolicyModule
          ),
      },
      {
        path: 'terms-condition',
        loadChildren: () =>
          import(
            'app/modules/auth/terms-condition/terms-condition.module'
          ).then((m) => m.TermsConditionModule),
      },
      {
        path: 'email-verify/:user_id',
        loadChildren: () =>
          import('app/modules/auth/email-verify/email-verify.module').then(
            (m) => m.EmailVerifyModule
          ),
      },
      {
        path: 'set-password/:user_id',
        loadChildren: () =>
          import('app/modules/auth/set-password/set-password.module').then(
            (m) => m.SetPasswordModule
          ),
      },
      {
        path: 'reset-redirect',
        loadChildren: () =>
          import('app/modules/auth/reset-pass-redirect/reset-pass-redirect.module').then(
            (m) => m.ResetPassRedirectModule
          ),
      },

    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing home routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    resolve: {
    },

    children: [
      {
        path: ':user_id',
        loadChildren: () =>
          import(
            'app/modules/admin/pages/company-user-profile/company-user-profile.module'
          ).then((m) => m.CompanyUserProfileModule),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      // Dashboards
      {
        path: 'dashboards',
        children: [
          {
            path: 'project',
            loadChildren: () =>
              import(
                'app/modules/admin/dashboards/project/project.module'
              ).then((m) => m.ProjectModule),
          },
          {
            path: 'analytics',
            loadChildren: () =>
              import(
                'app/modules/admin/dashboards/analytics/analytics.module'
              ).then((m) => m.AnalyticsModule),
          },

          // created for posts by zeeshan
          {
            path: 'news',
            loadChildren: () =>
              import(
                'app/modules/admin/dashboards/posts/posts.module'
              ).then((m) => m.PostsModule),
          },
          {
            path: 'post/:id',
            component: PostDetail,
          },

        ],
      },


      // Apps
      {
        path: 'apps',
        children: [
          {
            path: 'calendar',
            loadChildren: () =>
              import('app/modules/admin/apps/calendar/calendar.module').then(
                (m) => m.CalendarModule
              ),
          },
          {
            path: 'chat', loadChildren: () =>
              import('app/modules/admin/apps/chat/chat.module').then
                ((m) => m.ChatModule)
          },
          {
            path: 'certificate', loadChildren: () =>
              import('app/modules/admin/apps/certificate/certificate.module').then
                ((m) => m.CertificateModule)
          },
          {
            path: 'employees',
            loadChildren: () =>
              import('app/modules/admin/apps/contacts/contacts.module').then(
                (m) => m.ContactsModule
              ),
          },
          {
            path: 'ecommerce',
            loadChildren: () =>
              import('app/modules/admin/apps/ecommerce/ecommerce.module').then(
                (m) => m.ECommerceModule
              ),
          },
          {
            path: 'file-manager',
            loadChildren: () =>
              import('app/modules/admin/apps/file-manager/file-manager.module').then(
                (m) => m.FileManagerModule),
          },
          {
            path: 'help-center',
            loadChildren: () =>
              import('app/modules/admin/apps/help-center/help-center.module').then(
                (m) => m.HelpCenterModule),
          },
          {
            path: 'jobs',
            loadChildren: () =>
              import('app/modules/admin/apps/jobs/jobs.module').then(
                (m) => m.JobsModule),
          },
          {
            path: 'licence', loadChildren: () =>
              import('app/modules/admin/apps/licence/licence.module').then
                ((m) => m.LicenceModule)
          },
          {
            path: 'mailbox',
            loadChildren: () =>
              import('app/modules/admin/apps/mailbox/mailbox.module').then(
                (m) => m.MailboxModule
              ),
          },
          {
            path: 'material',
            loadChildren: () =>
              import('app/modules/admin/apps/material/material.module').then(
                (m) => m.MaterialModule
              ),
          },
          {
            path: 'product',
            loadChildren: () =>
              import('app/modules/admin/apps/product/product.module').then(
                (m) => m.ProductModule
              ),
          },
          {
            path: 'services',
            loadChildren: () =>
              import('app/modules/admin/apps/services/services.module').then(
                (m) => m.ServicesModule
              ),
          },
          {
            path: 'tasks',
            loadChildren: () =>
              import('app/modules/admin/apps/tasks/tasks.module').then(
                (m) => m.TasksModule
              ),
          },
          {
            path: 'qualification',
            loadChildren: () =>
              import('app/modules/admin/apps/qualification/qualification.module').then(
                (m) => m.QualificationModule
              ),
          },
        ],
      },

      // Pages
      {
        path: 'pages',
        children: [
          // Activities
          {
            path: 'activities',
            loadChildren: () =>
              import
                ('app/modules/admin/pages/activities/activities.module').then((m) => m.ActivitiesModule)
          },

          // Authentication
          {
            path: 'authentication',
            loadChildren: () =>
              import(
                'app/modules/admin/pages/authentication/authentication.module'
              ).then((m) => m.AuthenticationModule),
          },

          // Coming soon
          {
            path: 'coming-soon',
            loadChildren: () =>
              import(
                'app/modules/admin/pages/coming-soon/coming-soon.module'
              ).then((m) => m.ComingSoonModule),
          },

          // Error
          {
            path: 'error',
            children: [
              {
                path: '404',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/error/error-404/error-404.module'
                  ).then((m) => m.Error404Module),
              },
              {
                path: '500',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/error/error-500/error-500.module'
                  ).then((m) => m.Error500Module),
              },
              {
                path: 'coming-soon',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/error/error-coming-soon/error-coming-soon.module'
                  ).then((m) => m.ErrorComingSoonModule),
              },

              {
                path: 'no-company',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/error/no-company/no-company.module'
                  ).then((m) => m.NoCompanyModule),
              },
            ],
          },

          // Invoice
          {
            path: 'invoice',
            children: [
              {
                path: 'printable',
                children: [
                  {
                    path: 'compact',
                    loadChildren: () =>
                      import(
                        'app/modules/admin/pages/invoice/printable/compact/compact.module'
                      ).then((m) => m.CompactModule),
                  },
                  {
                    path: 'modern',
                    loadChildren: () =>
                      import(
                        'app/modules/admin/pages/invoice/printable/modern/modern.module'
                      ).then((m) => m.ModernModule),
                  },
                ],
              },
            ],
          },

          // Maintenance
          {
            path: 'maintenance',
            loadChildren: () =>
              import(
                'app/modules/admin/pages/maintenance/maintenance.module'
              ).then((m) => m.MaintenanceModule),
          },
          // Pricing
          {
            path: 'pricing',
            children: [
              {
                path: 'modern',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/pricing/modern/modern.module'
                  ).then((m) => m.PricingModernModule),
              },
              {
                path: 'simple',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/pricing/simple/simple.module'
                  ).then((m) => m.PricingSimpleModule),
              },
              {
                path: 'single',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/pricing/single/single.module'
                  ).then((m) => m.PricingSingleModule),
              },
              {
                path: 'table',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/pricing/table/table.module'
                  ).then((m) => m.PricingTableModule),
              },
            ],
          },

          // Profile
          {
            path: 'profile',
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('app/modules/admin/pages/profile/profile.module').then(
                    (m) => m.ProfileModule
                  ),
              },

              {
                path: 'followers',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/followers/followers.module'
                  ).then((m) => m.FollowersModule),
              },

              {
                path: 'following',
                loadChildren: () =>
                  import(
                    'app/modules/admin/pages/following/following.module'
                  ).then((m) => m.FollowingModule),
              },
            ],
          },

          // search component
          {
            path: 'search',
            loadChildren: () =>
              import('app/modules/admin/pages/search/search.module').then(
                (m) => m.SearchModule
              ),
          },

          // company user profile component
          {
            path: 'user-profile/:user_id',
            loadChildren: () =>
              import(
                'app/modules/admin/pages/company-user-profile/company-user-profile.module'
              ).then((m) => m.CompanyUserProfileModule),
          },

          // Settings
          { path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.module').then(m => m.SettingsModule) },
        ],
      },

      // User interface
      {
        path: 'ui',
        children: [
          // Angular Material
          {
            path: 'angular-material',
            loadChildren: () =>
              import(
                'app/modules/admin/ui/angular-material/angular-material.module'
              ).then((m) => m.AngularMaterialModule),
          },

          // TailwindCSS
          {
            path: 'tailwindcss',
            loadChildren: () =>
              import(
                'app/modules/admin/ui/tailwindcss/tailwindcss.module'
              ).then((m) => m.TailwindCSSModule),
          },

          // Animations
          {
            path: 'animations',
            loadChildren: () =>
              import('app/modules/admin/ui/animations/animations.module').then(
                (m) => m.AnimationsModule
              ),
          },

          // Cards
          {
            path: 'cards',
            loadChildren: () =>
              import('app/modules/admin/ui/cards/cards.module').then(
                (m) => m.CardsModule
              ),
          },

          // Colors
          {
            path: 'colors',
            loadChildren: () =>
              import('app/modules/admin/ui/colors/colors.module').then(
                (m) => m.ColorsModule
              ),
          },

          // Datatable
          {
            path: 'datatable',
            loadChildren: () =>
              import('app/modules/admin/ui/datatable/datatable.module').then(
                (m) => m.DatatableModule
              ),
          },

          // Forms
          {
            path: 'forms',
            children: [
              {
                path: 'fields',
                loadChildren: () =>
                  import(
                    'app/modules/admin/ui/forms/fields/fields.module'
                  ).then((m) => m.FormsFieldsModule),
              },
              {
                path: 'layouts',
                loadChildren: () =>
                  import(
                    'app/modules/admin/ui/forms/layouts/layouts.module'
                  ).then((m) => m.FormsLayoutsModule),
              },
              {
                path: 'wizards',
                loadChildren: () =>
                  import(
                    'app/modules/admin/ui/forms/wizards/wizards.module'
                  ).then((m) => m.FormsWizardsModule),
              },
            ],
          },

          // Icons
          {
            path: 'icons',
            loadChildren: () =>
              import('app/modules/admin/ui/icons/icons.module').then(
                (m) => m.IconsModule
              ),
          },

          // Page layouts
          {
            path: 'page-layouts',
            loadChildren: () =>
              import(
                'app/modules/admin/ui/page-layouts/page-layouts.module'
              ).then((m) => m.PageLayoutsModule),
          },

          // Typography
          {
            path: 'typography',
            loadChildren: () =>
              import('app/modules/admin/ui/typography/typography.module').then(
                (m) => m.TypographyModule
              ),
          },
        ],
      },

      // Documentation
      {
        path: 'docs',
        children: [
          // Changelog
          {
            path: 'changelog',
            loadChildren: () =>
              import('app/modules/admin/docs/changelog/changelog.module').then(
                (m) => m.ChangelogModule
              ),
          },

          // Guides
          {
            path: 'guides',
            loadChildren: () =>
              import('app/modules/admin/docs/guides/guides.module').then(
                (m) => m.GuidesModule
              ),
          },

          // Core features
          {
            path: 'core-features',
            loadChildren: () =>
              import(
                'app/modules/admin/docs/core-features/core-features.module'
              ).then((m) => m.CoreFeaturesModule),
          },

          // Other components
          {
            path: 'other-components',
            loadChildren: () =>
              import(
                'app/modules/admin/docs/other-components/other-components.module'
              ).then((m) => m.OtherComponentsModule),
          },
        ],
      },

      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
          import(
            'app/modules/admin/pages/error/error-404/error-404.module'
          ).then((m) => m.Error404Module),
      },
      { path: '**', redirectTo: '404-not-found' },
    ],
  },
];
