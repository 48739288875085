<div class="flex flex-col flex-auto min-w-0 ">
<div class="dark bg-card dark:border-b">
    <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8">
        <div class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-8 sm:my-12">
            <div class="flex flex-auto items-center min-w-0">
                <div class="flex-0 w-16 h-16 rounded-full overflow-hidden">
                    <img alt="Card cover image" class="w-full h-full object-cover"
                        src="assets/images/logo/logo.png" />
                </div>
                <div class="flex flex-col min-w-0 ml-4">
                    <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-10 truncate">
                        News
                    </div>
                    <div class="flex-welcome-tag">
                        Welcome
                    </div>
                </div>
            </div>

            <div class="flex items-center mt-6 sm:mt-0 sm:ml-2 space-x-3">
                <!-- commented by zeeshan -->
                <button class="fuse-mat-button-rounded bg-accent-600" mat-flat-button [color]="'accent'"
                    [routerLink]="['/apps/chat']">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:mail'"></mat-icon>
                    <span class="ml-2">Messages</span>
                </button>
                <button class="fuse-mat-button-rounded" mat-flat-button [color]="'primary'"
                    [routerLink]="['/pages/profile/edit-profile']">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:cog'"></mat-icon>
                    <span class="ml-2">Settings</span>
                </button>
            </div>
        </div>
        <!-- commented by zeeshan -->
        <div class="flex items-center">
            <div class="px-4 py-2 rounded-tl-xl overflow-hidden bg-hover">
                <div class="sm:text-lg leading-6 truncate">
                    <!-- {{ user.company_name }} -->
                </div>
            </div>
            <div class="ml-px rounded-tr-xl bg-hover">
                <button [matMenuTriggerFor]="projectsMenu" mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:dots-horizontal'"></mat-icon>
                </button>
                <mat-menu #projectsMenu="matMenu">
                    <button mat-menu-item
                        [routerLink]="['/pages/profile/edit-profile']">
                        Edit
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-col flex-auto min-w-0"> 
    <div class="detail-container">
        <div (click)="backClicked()" class="back-icon"><mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:arrow-left'"></mat-icon></div>
        <div class="detail-screen-wrapper">
            <div class="image-tag">
                <img [src]="varient_backend+post?.image_slider"
                alt="Card cover image" />
            </div>
        </div>
        
    <h1>{{ post?.title?.length > 40 ? (post?.title | slice:0:200)+'..':post?.title }}: {{post?.created_at  ? (post?.created_at | date:'longDate') : (post?.updated_at | date:'mediumDate')}}</h1>
    <p [innerHTML]= "post?.content"></p>
    <p *ngIf="post?.content.length === 0"> {{post?.summary}} </p>
        <owl-carousel-o [options]="customOptions" class="carousal-panel">
            <ng-container *ngFor="let images of post?.additional_images">
                <ng-template carouselSlide>
                <img [src]="varient_backend+images?.image_big" alt="Card cover image">
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div> 
</div>
</div> 
<!-- <div class="flex flex-col flex-auto min-w-0"> -->
    <!-- <div class="dark bg-card dark:border-b"> -->
        <!-- <div class="flex flex-col w-full max-w-screen-xl mx-auto px-6 sm:px-8"> -->
            <!-- <div class="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 my-8 sm:my-12"> -->
                 
                <!-- <div class="flex flex-auto items-center min-w-0">
                    <div class="flex-0 w-16 h-16 rounded-full overflow-hidden">
                        <img class="w-full h-full object-cover" alt="logo" src="assets/images/logo/logo.png">
                    </div>
                    <div class="flex flex-col min-w-0 ml-4">
                        <div class="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-10 truncate">News</div>
                        
                    </div>
                </div> -->

                <!-- <div class="flex items-center mt-6 sm:mt-0 sm:ml-2 space-x-3"> -->
                    <!-- commented by zeeshan -->
                    <!-- <span >
                        <button class="fuse-mat-button-rounded bg-accent-600" mat-flat-button [color]="'accent'" [routerLink]="['/dashboards/news']">
                            <mat-icon
                                class="icon-size-5"
                                [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                            <span class="ml-2">Dashboard</span>
                        </button>
                    </span> -->
                   
                <!-- </div> -->
            <!-- </div> -->
        <!-- </div> -->
    <!-- </div> -->

    <!-- Header -->
    <!-- <div class="flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 pb-22 sm:pt-12 sm:pb-28 sm:px-10 bg-default dark:bg-transparent"> -->
        <!-- <div class="flex-1 min-w-0"> -->
            <!-- Breadcrumbs -->
            <!-- <div>
                <div class="hidden sm:flex flex-wrap items-center font-medium">
                    <div>
                        <a class="whitespace-nowrap text-primary-500">Post</a>
                    </div>
                    <div class="flex items-center ml-1 whitespace-nowrap">
                        <mat-icon
                            class="icon-size-5 text-secondary"
                            [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                        <a class="ml-1 text-primary-500">Details</a>
                    </div>  
                    <div class="flex items-center ml-1 whitespace-nowrap">
                        <mat-icon
                            class="icon-size-5 text-secondary"
                            [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                        <span class="ml-1 text-secondary">Overview</span>
                    </div>
                </div>
                <div class="flex sm:hidden">
                    <a
                        class="inline-flex items-center -ml-1.5 text-secondary font-medium"
                        [routerLink]="'./..'">
                        <mat-icon
                            class="icon-size-5 text-secondary"
                            [svgIcon]="'heroicons_solid:chevron-left'"></mat-icon>
                        <span class="ml-1">Back</span>
                    </a>
                </div>
            </div> -->
            <!-- Title -->
            <!-- <div class="mt-2">
           
                <h2 class="text-2xl font-semibold leading-tight">
                    {{ post.title }}
                </h2>
            </div> -->
        <!-- </div> -->
        <!-- Actions -->
        
    <!-- </div> -->

    <!-- <div class="flex-auto -mt-16 rounded-t-2xl shadow bg-card"> -->

        <!-- Drawer content -->
        <!-- <div class="flex flex-col"> -->

            <!-- Main -->
            <!-- <div class="flex-auto p-6 sm:p-10"> -->

                <!-- CONTENT GOES HERE -->
                <!-- <div class="border-2 border-dashed border-gray-300 rounded-2xl"> -->
                    <!-- Card #1 -->
                    <!-- <fuse-card class="flex flex-col w-full filter-article">
                    
                        <div class="m-8 mt-4">
                           
                            <div class="mt-4" [innerHTML]="post.content"></div>
                        </div>
                    </fuse-card> -->
                <!-- </div> -->

            <!-- </div> -->

        <!-- </div> -->

    <!-- </div> -->

<!-- </div> -->
