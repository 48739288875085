import { ChangeDetectorRef, EventEmitter, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { common, Application, BOOLEAN, commonMessages } from '../constants/index';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperComponent } from '../modules/admin/pages/image-cropper/image-cropper.component';
import { finalize } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config';
import { FuseConfirmationService } from '@fuse/services/confirmation/confirmation.service';
@Injectable({
  providedIn: 'root'
})
export class CommanFunctionService {
  public userUpdateHandler$ = new Subject<boolean>();
  public crroperData$ = new Subject<any>();
  dataByEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    readonly toastrService: ToastrService,
    readonly _matDialog: MatDialog,
    readonly _fuseConfigService: FuseConfigService,
    readonly _fuseConfirmationService: FuseConfirmationService,
  ) {

  }



  spaceValidator(control: AbstractControl): ValidationErrors | null {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue('');
      return { required: true };
    }
    else {
      return null;
    }
  }

  toastMessage(type, message): void {
    if (type === common.success) {
      this.toastrService.success(message, common.blank);
    }
    if (type === common.ERROR) {
      this.toastrService.error(message, common.blank);
    }
    if (type === common.WARNING) {
      this.toastrService.warning(message, common.blank);
    }
  }

  setTheme = () => {
    const ctheme: string = localStorage.getItem('curentTheme') || common.amber;
    this._fuseConfigService.config = {
      theme: ctheme,
    };
  }


  openCrroPerDialog = async (data, parentObj) => {
    const dialogRef = this._matDialog.open(ImageCropperComponent, {
      panelClass: 'dailog-class',
      data: data,
      disableClose: BOOLEAN.TRUE,
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        this.crroperData$.next(result);
      });
  }


  preventspace = (event) => {
    if (
      event.target.selectionStart === Application.ZERO &&
      event.code === common.SPACE
    ) {
      event.preventDefault();
    }
  }
    numberOnly = (event) => {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > Application.THIRTYONE && (charCode < Application.FORTYEIGHT || charCode > Application.FIFTYSEVEN)) {
      return false;
    }
    return true;
  }

  replaceUndefinied = (item) => {
    const str =  JSON.stringify(item,  (key, value) => {
      return (value === 'undefined') ? '' : value;
    });
    return JSON.parse(str);
 }

  openDialog(): void {
    // Open the confirmation dialog
    const confirmation = this._fuseConfirmationService.open({
      title: common.Message,
      message: commonMessages.next_relies,
      icon: {
        show: true,
        name: 'heroicons_outline:information-circle',
        color: 'primary'
      },
      actions: {
        confirm: {
          label: 'close',
          color: 'primary'
        },
        cancel: {
          show: false,
        }
      }
    });
  }

  sendCategoryDataByEvent = (data: object) => {
    this.dataByEvent.emit(data);
  }

}
