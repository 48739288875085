import { InjectionToken } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
export const FUSE_APP_CONFIG = new InjectionToken<any>('FUSE_APP_CONFIG');

export const customOptions: OwlOptions = {
  navText: [ '<i class="fa-chevron-left"></i>', '<i class="fa-chevron-right></i>"' ],
  loop: true,
  mouseDrag: false,
  touchDrag: false,
  pullDrag: false,
  dots: false,
  autoplay: true,
  navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }