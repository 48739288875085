import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { common, Application, BOOLEAN, errorMessages } from './constants/index';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'justTESt';
    toastRef;
    /**
     * Constructor
     */
    constructor(
        readonly connectionService: ConnectionService,
        readonly toastr: ToastrService,
        readonly router: Router,
        readonly fuseSplashService: FuseSplashScreenService
    ) {
        this.connectionService.monitor().subscribe(isConnected => {
            if (!isConnected) {
                this.showToast();
                return;
            }
        });
        this.router.events.subscribe((e: RouterEvent) => {
            this.navigationInterceptor(e);
        })
    }
    showToast = () => {
        this.toastRef = this.toastr.error(errorMessages.INTERNET_NOT_CONNECT, null, {
            disableTimeOut: BOOLEAN.TRUE,
            positionClass: common.TOASTPOSITION,
        });
    }
    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.fuseSplashService.show();
        }
        if (event instanceof NavigationEnd) {
            this.fuseSplashService.hide();
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.fuseSplashService.hide();
        }
        if (event instanceof NavigationError) {
            this.fuseSplashService.hide();
        }
    }

}
