import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService } from '@fuse/components/navigation';
import { InitialData } from 'app/app.types';
import { User } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';
import { FuseDrawerService } from '@fuse/components/drawer';
import { CommanFunctionService } from '../../../../shared/comman-function.service';
import { BOOLEAN } from 'app/constants';
@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    data: InitialData;
    user: User;
    isScreenSmall: boolean;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    readonly destroyed$ = new Subject();
    /**
     * Constructor
     */
    constructor(
        readonly _activatedRoute: ActivatedRoute,
        readonly _router: Router,
        readonly _fuseMediaWatcherService: FuseMediaWatcherService,
        readonly _fuseNavigationService: FuseNavigationService,
        readonly _userService: UserService,
        readonly _fuseDrawerService: FuseDrawerService,
        readonly _cmnFunction: CommanFunctionService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.useData();
        // Subscribe to the resolved route mock-api
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                // Mark for check
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this._cmnFunction.userUpdateHandler$.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            if (res) {
                this.useData();
            }
        });

    }

    useData(): void {
        this._userService.getUser().subscribe(res => {
            this.user = res;
        });
    }


    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._cmnFunction.userUpdateHandler$.next(BOOLEAN.FALSE);

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    toggleDrawer(name: string): void {
        // Get the drawer
        const drawer = this._fuseDrawerService.getComponent(name);

        // Toggle the opened status
        if (drawer) {
            drawer.toggle();
        }
    }
}
