import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { FuseAnimations } from '@fuse/animations/public-api';
import { environment } from 'environments/environment';
import { UserService } from 'app/core/user/user.service';
import { I } from '@angular/cdk/keycodes';
import { BOOLEAN } from 'app/constants';


@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: FuseAnimations
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    results: any[];
    searchControl: FormControl = new FormControl();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2,
        private _userService: UserService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM and we can focus on the input..
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {

                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {

                    // Set the search results to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if (!value || value.length < this.minLength) {
                        this.results = null;
                    }


                    // Continue
                    return value;
                }),
                filter((value) => {
                    return value && value.length >= this.minLength;

                })
            )
            .subscribe((value) => {

                var httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                    })
                };
                forkJoin([
                    this._httpClient.post('api/common/search', { query: value }),
                    this._httpClient.get<any>(environment.backend_url + 'backend/get-all-users/' + value, httpOptions)


                ])
                    .pipe(
                        tap(([fuseSearch, getAllUsers]) => {
                            getAllUsers.data = getAllUsers.data.filter(item => {
                                return (item.user_name && item.user_name.toLowerCase().includes(value.toLowerCase()))
                                    || (item.csi_skills_code !== undefined && item.csi_skills_code
                                        && item.csi_skills_code.replace(/\s/g, '').toLowerCase().includes(value.toLowerCase()))
                                    || (item.name !== undefined && item.name && item.name.toLowerCase().includes(value.toLowerCase()))
                                    || (item.profile_private !== BOOLEAN.TRUE);
                                    
                            }

                            );

                            for (var i = 0; i < getAllUsers.data.length; i++) {
                                if ('profile_pic' in getAllUsers.data[i]) {
                                    getAllUsers.data[i]['profile_pic'] = environment.BackendUrl + getAllUsers.data[i]['profile_pic'];
                                } else {
                                    getAllUsers.data[i]['profile_pic'] = null;
                                }
                                const re = new RegExp('(' + value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + ')', 'ig');

                                if (getAllUsers.data[i].name !== undefined && getAllUsers.data[i].name) {
                                    getAllUsers.data[i].name = getAllUsers.data[i].name.replace('undefined', '').replace(re, '<mark>$1</mark>');
                                }

                                if (getAllUsers.data[i].name === undefined && getAllUsers.data[i].user_name != undefined && getAllUsers.data[i].user_name) {
                                    getAllUsers.data[i].name = getAllUsers.data[i].user_name.replace('undefined', '').replace(re, '<mark>$1</mark>');
                                }
                                getAllUsers.data[i]['link'] = '/' + getAllUsers.data[i].id;

                            }
                        })

                    )
                    .subscribe((res: any) => {
                        this.results = [...res[0].results, ...res[1].data];
                        this.search.next(this.results);
                    });
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Listen for escape to close the search
        // if the appearance is 'bar'
        if (this.appearance === 'bar') {
            // Escape
            if (event.code === 'Escape') {
                // Close the search
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }
}
