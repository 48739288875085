import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { Layout } from 'app/layout/layout.types';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { BOOLEAN } from 'app/constants';
@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  designations;
  readonly settingCog: BehaviorSubject<boolean | false> = new BehaviorSubject(BOOLEAN.FALSE);
  constructor(readonly _httpClient: HttpClient) {
  }

  crruntUserTheme(data): Observable<any> {
    const curent_them = { 'color': data };
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .post(
        environment.backend_url + 'backend/update-theme ',
        curent_them,
        httpOptions
      );
  }
}
