import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { common, Application, BOOLEAN } from 'app/constants';
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
})
export class ImageCropperComponent implements OnInit, OnDestroy {
  imageChangedEvent = '';
  curentRatio = JSON.parse(localStorage.getItem(common.fromCover));
  croppedImage = '';
  crropedFile;
  constructor(
    readonly dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }

  ngOnInit(): void {
    this.imageChangedEvent = this.data;
  }
  imageCropped(event: ImageCroppedEvent): void {
    this.crropedFile = event.base64;
  }

  imageLoaded(): void {
    // show cropper
  }
  cropperReady(): void {
    // cropper ready
  }
  loadImageFailed(): void {
    // show message
  }


  sendPopupData(): void {
    if (JSON.parse(localStorage.getItem(common.INVENTORYTAG))) {
      this.dialogRef.close(this.crropedFile);
    } else {
      const goingData = this.base64ToFile(this.crropedFile, `${Date.now()}.jpeg`);
      this.dialogRef.close(goingData);
    }

  }

  closePopup(): void {
    this.dialogRef.close(common.goBack);
  }

  base64ToFile = (data, filename) => {
    const arr = data.split(',');
    const mime = arr[Application.ZERO].match(/:(.*?);/)[Application.ONE];
    const bstr = atob(arr[Application.ONE]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  ngOnDestroy(): void {
    localStorage.removeItem(common.INVENTORYTAG);
    localStorage.removeItem(common.fromCover);
  }
}
