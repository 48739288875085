import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { Router } from '@angular/router';
import { PostService } from 'app/modules/admin/dashboards/posts/posts.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Application } from 'app/constants';

@Component({
    selector: 'detail',
    templateUrl: './detail.component.html',
    encapsulation: ViewEncapsulation.None
})
export class PostDetail implements OnInit, OnDestroy {
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    readonly _unsubscribeAll: Subject<any> = new Subject<any>();

    readonly routeSub: any;
    postId: any;
    post: any;
    varient_backend = environment.varient_backend_url;


    /**
     * Constructor
     */
    constructor(
        readonly _fuseMediaWatcherService: FuseMediaWatcherService,
        readonly router: Router,
        readonly _postService: PostService,
    ) {
        this.postId = /[^/]*$/.exec(router.url)[Application.ZERO];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
         this.getPostDetails(this.postId);  
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getPostDetails(postId): void {

        this._postService.getPostDetails(postId).pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                // Store the data
                this.post = data.success[Application.ZERO].data.post;
            });
    }
    
}
