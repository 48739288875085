import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkillsComponent } from 'app/modules/admin/pages/skills/skills.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    SkillsComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatCheckboxModule,
    MatTreeModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatSelectModule,
    MatSidenavModule,
    MatRippleModule,
    MatDividerModule,
    MatButtonModule
  ],
  exports: [
    SkillsComponent
  ]
})
export class SkillsModule {
}
