import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchMap, tap, takeUntil } from 'rxjs/operators';
import { User } from 'app/core/user/user.model';
import { environment } from '../../../environments/environment';
import { projects } from 'app/modules/admin/pages/profile/projects/projects.types';
import { portfolio } from 'app/modules/admin/pages/profile/portfolio/portfolio.types';
import { Skills } from 'app/modules/admin/pages/profile/portfolio/portfolio.types';
import { postFeed } from 'app/modules/admin/pages/profile/portfolio/portfolio.types';
import { activities } from 'app/modules/admin/pages/profile/portfolio/portfolio.types';
import { Application, BOOLEAN } from 'app/constants';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  readonly updateTHeme: BehaviorSubject<boolean | false> = new BehaviorSubject(BOOLEAN.FALSE);
  private _user: ReplaySubject<User>                        = new ReplaySubject<User>(Application.ONE);
  private _projects: BehaviorSubject<projects | null>       = new BehaviorSubject(null);
  private _project: BehaviorSubject<any | null>             = new BehaviorSubject(null);
  private _portfolios: BehaviorSubject<portfolio | null>    = new BehaviorSubject(null);
  private _portfolioedit: BehaviorSubject<portfolio | null> = new BehaviorSubject(null);
  private _skills: BehaviorSubject<Skills | null>           = new BehaviorSubject(null);
  private _postFeed: BehaviorSubject<postFeed | null>       = new BehaviorSubject(null);
  private _activities: BehaviorSubject<activities | null>   = new BehaviorSubject(null);
  private _positions: BehaviorSubject<activities | null>    = new BehaviorSubject(null);
  public loginUserId: any;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) { }
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for Update user of Fuse Theme
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  /**
   * Setter & getter for getSkills(search)
   *
   * @param value
   */
  set skills(value: any) {
    // Store the value
    this._skills.next(value);
  }

  get skills$(): Observable<any> {
    return this._skills.asObservable();
  }


  /**
   * Setter & getter for getPositions
   *
   * @param value
   */
  set positions(value: any) {
    // Store the value
    this._positions.next(value);
  }

  get positions$(): Observable<any> {
    return this._positions.asObservable();
  }

  /**
   * Setter & getter for projects Listing
   *
   * @param value
   */
  set projects(value: any) {
    // Store the value
    this._projects.next(value);
  }

  get projects$(): Observable<any> {
    return this._projects.asObservable();
  }

  /**
   * Setter & getter for Project By ID
   *
   * @param value
   */
  set project(value: any) {
    // Store the value
    this._project.next(value);
  }

  get project$(): Observable<any> {
    return this._project.asObservable();
  }

  /**
   * Setter & getter for portfolio Listing
   *
   * @param value
   */
  set portfolios(value: any) {
    // Store the value
    this._portfolios.next(value);
  }

  get portfolios$(): Observable<any> {
    return this._portfolios.asObservable();
  }

  /**
   * Setter & getter for getPortfolio By ID
   *
   * @param value
   */
  set postFeed(value: any) {
    // Store the value
    this._postFeed.next(value);
  }

  get postFeed$(): Observable<any> {
    return this._postFeed.asObservable();
  }


  /**
   * Setter & getter for getActivities By ID
   *
   * @param value
   */
  set activities(value: any) {
    // Store the value
    this._activities.next(value);
  }

  get activities$(): Observable<any> {
    return this._activities.asObservable();
  }

  /** 
   * Setter & getter for all post
   * 
   * @param value
   * */
  set portfolioedit(value: any) {
    // Store the value
    this._portfolioedit.next(value);
  }

  get portfolioedit$(): Observable<any> {
    return this._portfolioedit.asObservable();
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user
   *
   * @param user
   */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        // Execute the observable
        this._user.next(response);
      })
    );
  }

  /**
   * Get the user
   */
  getUser() {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-current-user',
        httpOptions
      )
      .pipe(
        switchMap((res) => {
          var userData = res.data[Application.ZERO];
          userData['status'] = 'online';
          if ('profile_pic' in userData) {
            userData['avatar'] = environment.BackendUrl + userData['profile_pic'];
          } else {
            userData['avatar'] = 'assets/images/pages/default/profile-default.png';
          }
          if (!('cover_photo' in userData)) {
            userData['cover_photo'] = 'assets/images/pages/profile/cover.jpeg';
          } else {
            userData['cover_photo'] = environment.BackendUrl + userData['cover_photo'];
          }
          this.user = userData;
          this.loginUserId = userData['user_id'];
          return of(userData);
        })
      );
  }

  /**
   * edit profile page form
   */

  updateProfile(data: any): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .post(
        environment.backend_url + 'backend/update-current-profile',
        data,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          var userData        = res.data[Application.ZERO];
          userData['status']  = 'online';
          if ('profile_pic' in userData) {
            userData['avatar'] = environment.BackendUrl + userData['profile_pic'];
          } else {
            userData['avatar'] = 'assets/images/pages/default/profile-default.png';
          }
          if (!('cover_photo' in userData)) {
            userData['cover_photo'] = 'assets/images/pages/profile/cover.jpeg';
          } else {
            userData['cover_photo'] = environment.BackendUrl + userData['cover_photo'];
          }
          this.user = userData;
          this.loginUserId = userData['user_id'];
          userData['res'] = res.res;
          userData['message'] = res.message;
          return userData;
       

        })
      );
  }

  DeleteAvatar(data: any): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };

    return this._httpClient
      .post(
        environment.backend_url + 'backend/remove-avatar',
        data,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          var userData        = res.data[Application.ZERO];
          userData['status']  = 'online';
          if ('profile_pic' in userData) {
            userData['avatar'] = environment.BackendUrl + userData['profile_pic'];
          } else {
            userData['avatar'] = 'assets/images/pages/default/profile-default.png';
          }
          if (!('cover_photo' in userData)) {
            userData['cover_photo'] = 'assets/images/pages/profile/cover.jpeg';
          } else {
            userData['cover_photo'] = environment.BackendUrl + userData['cover_photo'];
          }
          this.user = userData;
          this.loginUserId = userData['user_id'];
          userData['res'] = res.res;
          userData['message'] = res.message;
          return userData;
        })
      );
  }
  /**
   * get skills API's
   */

  getSkills(parent_id: any = '') {
    parent_id = parent_id || '';
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-skills/' + parent_id,
        httpOptions
      )
      .pipe(
        switchMap((res) => {
          this.skills = res.data;
          return of(res.data);
        })
      );
  }

  /**
   * projects page form
   */

  getProjectsListing() {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-projects-listing',
        httpOptions
      )
      .pipe(
        switchMap((res) => {
          for (let i = Application.ZERO; i < res.data.length; i++) {
            let obj = res.data[i];
            if ((!(obj['project_pic'] == null ||
                  obj['project_pic'] == undefined)) &&
                (obj['project_pic'].length > Application.ZERO)){
              obj['image'] = environment.BackendUrl + obj['project_pic'][Application.ZERO]['path'];
            } else {
              obj['image'] = 'assets/images/pages/default/profile-default.png';
            }
          }
          this.projects = res.data;
          return of(res.data);
        })
      );
  }

  // create Projects
  createProjects(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient.post(
      environment.backend_url + 'backend/create-project',data,httpOptions);
  }

  // DeleteProjects
  deleteProject(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };

    return this._httpClient
      .post(
        environment.backend_url + 'backend/delete-project',
        data,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          this.getProjectsListing().subscribe((res: any) => {
            this.projects = res;
          });
        })
      );
  }

  //updateProjects
  updateProjects(data: any): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .post(
        environment.backend_url + 'backend/update-project',
        data,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          this.getProjectsListing().subscribe((res: any) => {
            this.projects = res;
          });
        })
      );
  }

  getProject(id) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-project/' + id,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          this.project = res.data[Application.ZERO];
        })
      );
  }
  /**
   * portfolio page form
   */

  getPortfolioListing() {
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-portfolio-listing',
        httpOptions
      )
      .pipe(
        switchMap((res) => {
          for (let i = Application.ZERO; i < res.data.length; i++) {
            let obj = res.data[i];
            if ('portfolio_image' in obj && obj['portfolio_image']) {
              obj['portfolio_image'] = environment.BackendUrl + obj['portfolio_image'];
            } else {
              obj['portfolio_image'] = 'assets/images/pages/default/portfolio-default.png';
            }
          }
          this.portfolios = res.data;
          return of(res.data);
        })
      );
  }

  // create Projects
  createPortfolio(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient.post(
      environment.backend_url + 'backend/add-portfolio',
      data,
      httpOptions
    );
  }

  // DeleteProjects
  deletePortfolio(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };

    return this._httpClient
      .post(
        environment.backend_url + 'backend/delete-portfolio',
        data,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          this.getPortfolioListing().subscribe((res: any) => {
            this.portfolios = res;
          });
        })
      );
  }

  //updateProjects
  updatePortfolio(data: any): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .post(
        environment.backend_url + 'backend/update-portfolio',
        data,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          this.getPortfolioListing().subscribe((res: any) => {
            this.portfolios = res;
          });
        })
      );
  }

  getPortfolio(id) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(
        environment.backend_url + 'backend/get-portfolio/' + id,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          if ('portfolio_image' in res.data[Application.ZERO]) {
            res.data[Application.ZERO]['portfolio_image'] =
              environment.BackendUrl + res.data[Application.ZERO]['portfolio_image'];
          } else {
            res.data[Application.ZERO]['portfolio_image'] = 'assets/images/pages/default/portfolio-default.png';
          }
          this.portfolioedit = res.data[Application.ZERO];
        })
      );
  }

  /**
   * Social Media Post Area API'S Functions Start From Here
   */

  //Add Post
  addPost(data: any): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient.post(environment.backend_url + 'backend/add-post', data, httpOptions)
      .pipe(
        map((res: any) => {
          this.getAllPost().subscribe((res: any) => { })
        })
      );
  }

  //getPost

  getAllPost() {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };

    return this._httpClient
      .get<any>(environment.backend_url + 'backend/get-all-post', httpOptions).pipe(switchMap((res) => {
        for (var i = Application.ZERO; i < res.data.length; i++) {
          var obj = res.data[i]
          if ('profile_pic' in obj) {
            obj['profile_pic'] = environment.BackendUrl + obj['profile_pic'];
          } else {
            obj['profile_pic'] = 'assets/images/pages/default/profile-default.png';
          }

          if ('media' in obj) {
            obj['media'].forEach(function (item, index) {
              if (obj['media'].length == Application.ONE) {
                obj['media'][Application.ZERO]['class'] = 'h-80'
              }

              if (obj['media'].length == Application.TWO) {
                obj['media'][Application.ZERO]['class'] = 'h-80'
                obj['media'][Application.ONE]['class'] = 'h-80'
              }

              if (obj['media'].length == Application.THREE) {
                obj['media'][Application.ZERO]['class'] = 'h-80'
                obj['media'][Application.ONE]['class'] = 'h-40'
                obj['media'][Application.TWO]['class'] = 'h-40'
              }

              if (obj['media'].length == Application.FOUR) {
                obj['media'][Application.ZERO]['class'] = 'h-40'
                obj['media'][Application.ONE]['class'] = 'h-40'
                obj['media'][Application.TWO]['class'] = 'h-40'
                obj['media'][Application.THREE]['class'] = 'h-40'
              }
              item['path'] = environment.BackendUrl + item['path']
            })
          }

        }
        this.postFeed = res.data;
        return of(res.data);
      })
      );
  }

  //DeletPost
  hidePost(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };

    return this._httpClient.post(environment.backend_url + 'backend/hide-post', data, httpOptions).pipe(
      map((res: any) => {
        this.getAllPost().subscribe((res: any) => {
          this.postFeed = res;
        });
      })
    );
  }

  //postLike
  likeUnlikePost(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient.post(environment.backend_url + 'backend/post-like/', data, httpOptions).pipe(
      map((res: any) => {
        this.getAllPost().subscribe((result) => { });
      })
    );

  }

  // comment like Unlike
  likeComment(data) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient.post(environment.backend_url + 'backend/comment-like' , data ,httpOptions).pipe(
      map((res: any) => {
      })
    );
  }


  //post Comments
  commentPost(data: any): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient.post(environment.backend_url + 'backend/add-post-comment', data, httpOptions)
      .pipe(map((res: any) => {
      }));
  }

  //get Comments

  getComment(post_id): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(environment.backend_url + 'backend/get-post-comments/' + post_id, httpOptions).pipe(
        switchMap((res) => {
          for (var i = Application.ZERO; i < res.data.length; i++) {
            var obj = res.data[i]
            if ('profile_pic' in obj) {
              obj['profile_pic'] = environment.BackendUrl + obj['profile_pic'];
            } else {
              obj['profile_pic'] = 'assets/images/pages/default/profile-default.png';
            }
          }
          return of(res.data);
        })
      );
  }

  //get Replies
  getCommentReplies(post_id, comment_id): Observable<any> {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(environment.backend_url + 'backend/get-comment-replies/' + post_id + '/' + comment_id, httpOptions).pipe(
        switchMap((res) => {
          for (var i = Application.ZERO; i < res.data.length; i++) {
            var obj = res.data[i]
            if ('profile_pic' in obj) {
              obj['profile_pic'] = environment.BackendUrl + obj['profile_pic'];
            } else {
              obj['profile_pic'] = 'assets/images/pages/default/profile-default.png';
            }
          }
          return of(res.data);
        })
      );
  }

  //get activity

  getActivity(is_all) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };
    return this._httpClient
      .get<any>(environment.backend_url + 'backend/get-activities/' + is_all, httpOptions).pipe(
        switchMap((res) => {
          for (var i = Application.ZERO; i < res.data.length; i++) {
            var obj = res.data[i]
            if ('profile_pic' in obj) {
              obj['profile_pic'] = environment.BackendUrl + obj['profile_pic'];
            } else {
              obj['profile_pic'] = 'assets/images/pages/default/profile-default.png';
            }
          }
          this.activities = res.data
          return of(res.data);
        })
      );
  }

  deletePost(data: any) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };

    return this._httpClient.post(environment.backend_url + 'backend/delete-post', data, httpOptions).pipe(
      map((res: any) => {
      })
    );
  }


  deleteComment(post_id, comment_id) {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };

    return this._httpClient.post(environment.backend_url + 'backend/delete-comment' ,{post_id:post_id, comment_id:comment_id}, httpOptions).pipe(
      map((res: any) => {
      })
    );
  }

  getPositions() {
    var httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      }),
    };

    return this._httpClient.get(environment.backend_url + 'backend/get-positions', httpOptions).pipe(
      map((res: any) => {
        this.positions = res.data
        return res.data
      })
    );
  }
  
}
