import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageCropperRoutingModule } from './image-cropper-routing.module';
import {ImageCropperComponent} from './image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule, MatDialogRef , MAT_DIALOG_DATA} from '@angular/material/dialog';
@NgModule({
  declarations: [ImageCropperComponent],
  imports: [
    CommonModule,
    ImageCropperRoutingModule,
    ImageCropperModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
 ],
})
export class ImagecropperModule { }
