// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//     production 		: true,
//     backend_url     : 'https://api.bylt.app/api/',
//     BackendUrl      : 'https://api.bylt.app/',
//     frontendUrl     : 'https://os.bylt.app/',
//     SOCKET_ENDPOINT : 'https://api.bylt.app/'
// };

export const environment = {
    production 		: true,
    backend_url     : 'https://api.bylt.app/api/',
    BackendUrl      : 'https://api.bylt.app/',
    frontendUrl     : 'https://os.bylt.app/',
    SOCKET_ENDPOINT : 'https://api.bylt.app/',
	varient_url		: 'https://press.bylt.news/api/',
	varient_backend_url		: 'https://press.bylt.news/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

