<!-- Item wrapper -->
<div
    class="fuse-vertical-navigation-item-wrapper"
    [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper">

    <!-- Item with an internal link -->
    <a  (click)="item.title === news ? showNewsCategory() : ''" 
        class="fuse-vertical-navigation-item"
        *ngIf="item.link && !item.externalLink && !item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
        [routerLink]="[item.link]"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with an external link -->
    <a
        class="fuse-vertical-navigation-item"
        *ngIf="item.link && item.externalLink && !item.function && !item.disabled"
        [href]="item.link">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a function -->
    <div
        class="fuse-vertical-navigation-item"
        *ngIf="!item.link && item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
        (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item with an internal link and function -->
    <a
        class="fuse-vertical-navigation-item"
        *ngIf="item.link && !item.externalLink && item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}"
        [routerLink]="[item.link]"
        [routerLinkActive]="'fuse-vertical-navigation-item-active'"
        [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with an external link and function -->
    <a
        class="fuse-vertical-navigation-item"
        *ngIf="item.link && item.externalLink && item.function && !item.disabled"
        [href]="item.link"
        (click)="item.function(item)">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>

    <!-- Item with a no link and no function -->
    <div 
        class="fuse-vertical-navigation-item"
        *ngIf="!item.link && !item.function && !item.disabled"
        [ngClass]="{'fuse-vertical-navigation-item-active-forced': item.active}">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

    <!-- Item is disabled -->
    <div 
        class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled"
        *ngIf="item.disabled">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </div>

</div>

<!-- Item template -->
<ng-template #itemTemplate>
    <div class="news-navbar">
        <!-- Icon -->
        <div class="main-category">
            <span class="close-arrow" (click)="item.title === news ? showNewsCategory() : ''"  *ngIf="item.title === news && showCategories === false"></span>
            <span class="open-arrow" (click)="item.title === news ? showNewsCategory() : ''" *ngIf="item.title === news && showCategories === true"></span>
            <mat-icon
                class="fuse-vertical-navigation-item-icon"
                [ngClass]="item.classes?.icon"
                *ngIf="item.icon"
                [svgIcon]="item.icon"></mat-icon>

            <!-- Title & Subtitle -->
            <div class="fuse-vertical-navigation-item-title-wrapper">
                <div  class="fuse-vertical-navigation-item-title">
                    <span [ngClass]="item.classes?.title">
                        {{item.title}}
                    </span>
                </div>
            
            </div>

            <!-- Badge -->
            <div
                class="fuse-vertical-navigation-item-badge"
                *ngIf="item.badge">
                <div
                    class="fuse-vertical-navigation-item-badge-content"
                    [ngClass]="item.badge.classes">
                    {{item.badge.title}}
                </div>
            </div>

        </div>
        <div class="fuse-vertical-navigation-item-title-wrapper sub-category-bar" *ngIf="item.title === news && showCategories === true" [ngClass]="item.classes?.title">
            <div *ngFor="let items of item?.children" (click)="category(items.name)" class="inner-tag">
                {{items?.name}}
            </div>
            
        </div>
    </div>
</ng-template>
