<div>
  <image-cropper
  [imageChangedEvent]="imageChangedEvent"
  class="cropper-panel"
  [maintainAspectRatio]="true"
  [aspectRatio]="curentRatio ? 4 / 2 : 4 / 4"
  format="png"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
  <div class="button-section">
      <button class="cancel-button" (click)="closePopup()">Cancel</button>
    <button class="apply-button" (click)="sendPopupData()">Apply</button>
  </div>
</div>

