/**
 * Node for to-do item
 */
export class FoodNode {
  children?: FoodNode[];
  code: string;
  description: string;
  title: string;
}

/** Flat to-do item node with expandable and level information */
export class FoodFlatNode {
  code: string;
  description: string;
  title: string;
  level: number;
  expandable: boolean;
}
